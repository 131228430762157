import { Grid, Typography, useTheme } from '@mui/material';
import { FC, ReactElement } from 'react';

export interface IModalTitleProps {
    icon: ReactElement;
    title: string;
}

export const ModalTitle: FC<IModalTitleProps> = ({ icon, title }) => {
    const theme = useTheme();

    return (
        <Grid
            item
            container
            direction='row'
            alignItems='center'
            p={1}
            sx={{ background: theme.palette.primary.main, color: theme.palette.primary.contrastText }}
            position='sticky'
            top={0}
            zIndex={6}
            gap={1}>
            {icon}
            <Typography fontWeight='bold'>{title}</Typography>
        </Grid>
    )
}