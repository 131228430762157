import { useCallback } from 'react';
import { OpenAIConfigurationDto } from '../../../dtos';
import { IFormFieldValidationConfig, IFormProps, useStandardValidatedFormManager } from '../../CoreLib/library';

export interface IOpenAIConfigFormValues {
    openAIKey: string;
    organizationID?: string;
    model: string;
}

const DEFAULT_OpenAIConfig: OpenAIConfigurationDto = {
    openAIKey: '',
    model: '',
    organizationID: '',
    isOpenAiKeySet: false
};

const OPEN_AI_CONFIG_VALIDATION_CONFIG = new Map<keyof OpenAIConfigurationDto, IFormFieldValidationConfig>([]);

export function useOpenAIConfigForm({ save, cancel, initValues = DEFAULT_OpenAIConfig }: IFormProps<OpenAIConfigurationDto>) {
    const {
        formRecord: formOpenAIConfig,
        setFormRecord: setFormOpenAIConfig,
        fieldErrors,
        validateForm,
        handleTextFieldChange,
        isFormDirty
    } = useStandardValidatedFormManager(initValues, OPEN_AI_CONFIG_VALIDATION_CONFIG);

    const handleSave = () => {
        const isFormValid = validateForm();
        if (isFormValid) {
            save(formOpenAIConfig);
        }
    };

    const handleCancel = () => {
        cancel();
    };

    const handleModelChange = useCallback((value: string) => {
        setFormOpenAIConfig((prev) => ({
            ...prev,
            model: value
        }));
    }, [setFormOpenAIConfig]);


    return {
        isFormDirty,
        handleSave,
        handleCancel,
        fieldErrors,
        formOpenAIConfig,
        handleTextFieldChange,
        handleModelChange
    };
}
