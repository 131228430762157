import { Delete, DoubleArrow, Edit, History, PlayCircle } from '@mui/icons-material';
import { Grid, IconButton, Paper, Tooltip, Typography } from '@mui/material';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthenticatedComponent } from '../../../Components/CoreLib/library';
import { usePermissionChecker } from '../../../Hooks';
import { PlayDto } from '../../../dtos';
import { useConfirmDelete } from '../../../customHooks';
import { usePlayByIdArchiveMutation } from '../../../store/generated/generatedApi';

export interface IPlayCardProps {
    play: PlayDto;
    onStartClicked: (play: PlayDto) => void;
}

export const PlayCard: FC<IPlayCardProps> = (props) => {
    const { play, onStartClicked } = props;
    const navigate = useNavigate();
    const { canUserEditPlay, hasAllPermissions } = usePermissionChecker();
    const { setObjectToDelete, isDeletingObject, renderDeleteConfirmationModal } = useConfirmDelete('Play', usePlayByIdArchiveMutation);

    const handleEdit = useCallback(
        (item: PlayDto) => {
            navigate(`/playbook/${item.playbookId}/play/${item.id}`);
        },
        [navigate]
    );

    const handlePlayClicked: MouseEventHandler<HTMLButtonElement> = useCallback(
        (e) => {
            e.stopPropagation();
            onStartClicked(play);
        },
        [play, onStartClicked]
    );

    const handleShowDeleteConfirmationModal = useCallback(
        (objectToDelete: any) => {
            setObjectToDelete(objectToDelete);
        },
        [setObjectToDelete]
    );

    const goToRunHistoryPage = () => {
        navigate(`/playbook/${play.playbookId}/play/${play.id}/history`);
    };

    const goToDashboardPage = () => {
        navigate(`/playbook/${play.playbookId}/play/${play.id}/dashboard`);
    };

    const getPlaybookIcon = useCallback((play: PlayDto) => {
        const playIcon = new Date(play.createdOn).getTime() % 3;

        switch (playIcon) {
            case 0:
                return '/assets/Play-1-Lime.png';
            case 1:
                return '/assets/Play-2-Lime.png';
            case 2:
                return '/assets/Play-3-Lime.png';
            default:
                return '/assets/playbook-blank.png';
        }
    }, []);

    const canUserExecutePlays = useMemo(() => hasAllPermissions([`execute:plays`]), [hasAllPermissions]);

    return (
        <>
            {renderDeleteConfirmationModal}
            <Paper sx={{ height: '100%', cursor: 'pointer' }} className='hover-content-container' onClick={goToDashboardPage}>
                <Grid item container direction='column' justifyContent='space-between' height={1} flexWrap='nowrap'>
                    <Grid item container wrap='nowrap' direction='row' alignItems='center'>
                        <Grid
                            item
                            sx={{
                                background: `url(${getPlaybookIcon(play)})`,
                                backgroundSize: 'contain',
                                backgroundColor: '#ECEEC5',
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderTopLeftRadius: 4,
                            }}
                            width={60}
                            height={60}></Grid>
                        <Grid item container className='scrollable-text' px={1.5} pt={0.1} style={{ maxHeight: '62px' }}>
                            <Typography fontSize={20} fontWeight='bold' style={{ whiteSpace: 'normal', overflow: 'hidden' }}>
                                {play.name}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row'>
                        <Grid item className='scrollable-text' px={1.5} maxHeight={40} pt={0.5} maxWidth='100%'>
                            <Typography fontSize={14} style={{ whiteSpace: 'normal', overflow: 'hidden', wordBreak: 'break-word' }}>
                                {play.description}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container display='flex' alignItems='center' justifyContent='end' px={1} pb={1}>
                        <Grid item direction='column' marginRight='auto'>
                            <Tooltip title={`${play.stepCount ?? '0'} Step(s)`}>
                                <Grid item container direction='row' alignItems={'center'}>
                                    <Typography lineHeight={1} fontSize={24} fontWeight='bold' ml={1}>
                                        {play.stepCount}
                                    </Typography>
                                    <DoubleArrow sx={{ fontSize: 24 }} />
                                </Grid>
                            </Tooltip>
                            <AuthenticatedComponent requiredPermissions={[`read:developer`]}>
                                <Grid item textAlign={'center'}>
                                    <Typography fontWeight='bold' fontSize={10} lineHeight={1}>
                                        ({play.key})
                                    </Typography>
                                </Grid>
                            </AuthenticatedComponent>
                        </Grid>
                        <AuthenticatedComponent requiredPermissions={[`delete:plays`]}>
                            <Tooltip title='Delete'>
                                <IconButton
                                    size='small'
                                    sx={{
                                        fontSize: 30,
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleShowDeleteConfirmationModal(play);
                                    }}
                                    disabled={isDeletingObject}>
                                    <Delete fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                        </AuthenticatedComponent>
                        <AuthenticatedComponent requiredPermissions={[`read:playExecutions`]}>
                            <Tooltip title='Run History'>
                                <IconButton
                                    size='small'
                                    sx={{
                                        fontSize: 30,
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        goToRunHistoryPage();
                                    }}>
                                    <History fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                        </AuthenticatedComponent>
                        {canUserEditPlay(play) && (
                            <Tooltip title='Edit'>
                                <IconButton
                                    size='small'
                                    sx={{
                                        fontSize: 30,
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleEdit(play);
                                    }}>
                                    <Edit fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                        )}
                        {canUserExecutePlays && (
                            <Tooltip title={play.isValid ? 'Start' : 'Invalid plays cannot be started'}>
                                <IconButton
                                    color='secondary'
                                    size='small'
                                    sx={{
                                        fontSize: 30,
                                    }}
                                    onClick={handlePlayClicked}
                                    disabled={!play.isValid}>
                                    <PlayCircle fontSize='inherit' />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};
