import { CopyAll, DataObject, Help } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, Link, List, ListItem, ListItemText, Tooltip, Typography } from '@mui/material';
import _ from 'lodash';
import { FC, useCallback } from 'react';
import { StepDataType, StepExecutionDataDto } from '../../../dtos';
import { useCreateAttachmentByIdDownloadMutation } from '../../../store/generated/generatedApi';
import { downloadFile, formatTimestamp } from '../../../util';

export interface ICollectedDataPanelProps {
    collectedData: StepExecutionDataDto[];
}

export const CollectedDataPanel: FC<ICollectedDataPanelProps> = ({ collectedData }) => {
    const [createAttachmentByIdDownload, { isLoading: isCreatingDownloadLink }] = useCreateAttachmentByIdDownloadMutation();

    const handleFileLinkClicked = useCallback(
        async (stepExecutionId: string, fileName: string) => {
            if (isCreatingDownloadLink) {
                return;
            }
            var downloadLink = await createAttachmentByIdDownload({ id: stepExecutionId, fileName }).unwrap();
            downloadFile(downloadLink.link, fileName);
        },
        [createAttachmentByIdDownload, isCreatingDownloadLink]
    );

    return (
        <Grid item container direction='column' width='300px' height='100%' overflow='hidden' wrap='nowrap'>
            <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                bgcolor='primary.main'
                color='primary.contrastText'
                width='100%'
                gap={1}
                px={1}
                py={0.5}>
                <DataObject /> <Typography variant='h6'>Data Collected</Typography>
                <Tooltip title="These are the data values that have been collected by previous steps in this play.">
                    <IconButton color='inherit' disableRipple={true}>
                        <Help />
                    </IconButton>
                </Tooltip>
            </Box>
            <Box flexGrow={1} bgcolor='#fff' width='100%' sx={{ overflowY: 'auto' }}>
                <List sx={{ width: '100%' }} dense disablePadding>
                    {collectedData.length === 0 ? (
                        <ListItem>
                            <ListItemText primary='No Data Collected So Far' />
                        </ListItem>
                    ) : (
                        _.uniqBy(collectedData, 'name').map((cd) => (
                            <ListItem key={cd.id} divider disablePadding sx={{ pl: 1 }}>
                                <ListItemText
                                    primary={
                                        <Box display='flex' gap={1} alignItems='center'>
                                            <Box>
                                                <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                                                    {cd.name}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ width: '120px', marginLeft: 'auto' }}>
                                                <Button
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(cd.value);
                                                    }}
                                                    size='small'
                                                    variant='text'
                                                    endIcon={<CopyAll fontSize='small' />}
                                                    fullWidth>
                                                    Copy Value
                                                </Button>
                                            </Box>
                                        </Box>
                                    }
                                    secondary={
                                        <Box pr={.5}>
                                            {
                                                cd.dataType === StepDataType.FILE ? (
                                                    <Link href='#' underline='hover' onClick={() => handleFileLinkClicked(cd.stepId, cd.value)}>
                                                        {cd.value}
                                                    </Link>
                                                ) : (
                                                    formatTimestamp('', cd) || (
                                                        <Typography variant='body2' fontStyle='italic'>
                                                            No value
                                                        </Typography>
                                                    )
                                                )
                                            }
                                        </Box>
                                    }
                                />
                            </ListItem>
                        ))
                    )}
                </List>
            </Box>
        </Grid>
    );
};
