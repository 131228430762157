import { DoubleArrow } from '@mui/icons-material';
import { Dialog } from '@mui/material';
import { FC, useCallback } from 'react';
import { ApiError, LoadingIndicator, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../Components/CoreLib/library';
import { OpenAIConfigForm } from '../../../Components/Forms/OpenAIConfigForm';
import { useGetClientOpenAIConfigQuery, useUpdateClientOpenAIConfigMutation } from '../../../store/generated/generatedApi';
import { ModalTitle } from '../../Plays/Components/SubComponents';

export interface IConfigureOpenAICardProps {
    configureOpenAIModalOpen: boolean;
    setConfigureOpenAIModalOpen: (open: boolean) => void;
}

export const ConfigureOpenAICard: FC<IConfigureOpenAICardProps> = ({ configureOpenAIModalOpen, setConfigureOpenAIModalOpen }) => {
    const {
        data: openAiConfigDetails,
        error: isOpenAIConfigError,
        isLoading: isLoadingOpenAIConfig,
        refetch: refetchOpenAiConfig,
    } = useGetClientOpenAIConfigQuery();
    const [
        updateOpenAIConfig,
        { isError: isErrorUpdatingOpenAIConfig, isSuccess: isOpenAIConfigUpdated, isLoading: isUpdatingOpenAIConfig, reset: resetUpdateOpenAiConfig },
    ] = useUpdateClientOpenAIConfigMutation();

    const handleClose = useCallback(() => {
        setConfigureOpenAIModalOpen(false);
    }, [setConfigureOpenAIModalOpen]);

    useSuccessfulActionSnackbar('updated', 'OpenAI settings', isOpenAIConfigUpdated, handleClose);
    useFailedActionSnackbar('update', 'OpenAI settings', isErrorUpdatingOpenAIConfig, resetUpdateOpenAiConfig);

    if (isUpdatingOpenAIConfig || isLoadingOpenAIConfig) {
        return <LoadingIndicator />;
    }

    if (isOpenAIConfigError) {
        return <ApiError onReloadClick={refetchOpenAiConfig} />;
    }

    return (
        <Dialog open={configureOpenAIModalOpen ?? false} fullWidth maxWidth='md'>
            <ModalTitle icon={<DoubleArrow sx={{ mr: 1 }} />} title='Configure OpenAI' />
            <OpenAIConfigForm isClientConfigured={!!openAiConfigDetails?.isOpenAiKeySet} save={updateOpenAIConfig} cancel={handleClose} initValues={openAiConfigDetails} />
        </Dialog>
    );
};
