import {FC, useCallback, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { useGetSecureFileBySecureFileIdDownloadQuery } from '../../store/generated/generatedApi';
import { ApiError, LoadingIndicator } from '../../Components/CoreLib/library';
import { Box, Button, Paper, Typography } from '@mui/material';

export const SecureFileView: FC = () => {
    const { secureFileId } = useParams();
    const { data: secureFileSasLink, isLoading: isDownloadingSecureFile, isError: isErrorDownloadingSecureFile, error, refetch: retrySecureFileDownload } = useGetSecureFileBySecureFileIdDownloadQuery({ secureFileId: secureFileId! });

    const downloadFile = useCallback(async () => {
        if (!secureFileSasLink) {
            console.error('An attempt was made to open a secure file download link, but the link was not available.');
            return;
        }
        window.open(secureFileSasLink, '_blank');
    }, [secureFileSasLink]);

    useEffect(() => {
        if (secureFileSasLink) {
            downloadFile();
        }
    }, [secureFileSasLink, downloadFile]);

    if (!isDownloadingSecureFile && isErrorDownloadingSecureFile) {
        console.error(error);
        return <ApiError onReloadClick={retrySecureFileDownload} />;
    }

    if (isDownloadingSecureFile || !secureFileSasLink) {
        return <LoadingIndicator />;
    }
    
    return (
        <Box p={1} width='100%'>
            <Paper sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 1, p: 1 }}>
                <Typography variant='h3' align='center'>Secure File Download</Typography>
                <Typography>
                    The file download should start automatically, but if it does not then please click the button below.
                </Typography>
                <Button onClick={downloadFile} variant='contained' color='primary'>Download File</Button>
            </Paper>
        </Box>
    )
};