import { Edit } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { FormInput } from "../../../../Components/CoreLib/library";
import { ModalTitle } from "../SubComponents";

export interface ISetPlayExecutionNameModalProps {
    isOpen: boolean;
    existingName?: string;
    cancel: () => void;
    save: (name: string) => void;
}

export const SetPlayExecutionNameModal: FC<ISetPlayExecutionNameModalProps> = ({ isOpen, cancel, save, existingName }) => {
    const [name, setName] = useState('');

    const handleSave = useCallback(() => {
        save(name);
    }, [save, name]);

    const handleRunNameChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    }, []);

    useEffect(() => {
        setName(existingName ?? '')
    }, [existingName, isOpen])

    return (
        <Dialog open={isOpen} fullWidth maxWidth='sm'>
            <ModalTitle icon={<Edit />} title='Set Run Name' />
            <DialogContent>
                <FormInput label='Run Name' value={name} onChange={handleRunNameChange} fullWidth maxLength={500}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color='secondary' variant='outlined'>
                    Cancel
                </Button>
                <Button onClick={handleSave} color='secondary' variant='contained'>
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};