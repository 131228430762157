import {
    AddUserView,
    CompleteStepView,
    ConnectSlack,
    DebugView,
    EditUserView,
    Home,
    PlayDashboardView,
    PlayEditorExperienceRouter,
    PlayHistoryView,
    Playbooks,
    Plays,
    SecureFileView,
    UsersListView
} from '../Views';
import EmailSubscriptionView from '../Views/EmailSubscription/EmailSubscriptionView';
import { AddJobFunctionView } from '../Views/JobFunction/AddJobFunctionView';
import { EditJobFunctionView } from '../Views/JobFunction/EditJobFunctionView';
import { JobFunctionListView } from '../Views/JobFunction/JobFunctionListView';
import { BetaPlayEditorProvider } from '../Views/Plays/Contexts';
import { SignUp } from '../Views/SignUp';
import { UserSettingsView } from '../Views/UserSettings/UserSettingsView';
import { IRoute } from './types';

export const routes: IRoute[] = [
    {
        component: <Home />,
        path: '/',
        requiredPermissions: [],
    },
    {
        component: <UsersListView />,
        path: '/users',
        requiredPermissions: ['read:users'],
    },
    {
        component: <AddUserView />,
        path: '/user/create',
        requiredPermissions: ['create:users'],
    },
    {
        component: <EditUserView />,
        path: '/user/:id',
        requiredPermissions: ['edit:users'],
    },
    {
        component: <JobFunctionListView />,
        path: '/jobFunctions',
        requiredPermissions: ['read:jobFunctions'],
    },
    {
        component: <AddJobFunctionView />,
        path: '/jobFunction/create',
        requiredPermissions: ['create:jobFunctions'],
    },
    {
        component: <EditJobFunctionView />,
        path: '/jobFunction/:id',
        requiredPermissions: ['edit:jobFunctions'],
    },
    {
        component: <Playbooks />,
        path: '/playbooks',
        requiredPermissions: ['read:playbooks'],
    },
    {
        component: <Plays />,
        path: '/playbook/:id/plays',
        requiredPermissions: ['read:plays'],
    },
    {
        component: <BetaPlayEditorProvider><PlayEditorExperienceRouter /></BetaPlayEditorProvider>,
        path: '/playbook/:playbookId/play/:id',
        requiredPermissions: ['read:plays'],
    },
    {
        component: <PlayHistoryView />,
        path: '/playbook/:playbookId/play/:playId/history',
        requiredPermissions: ['read:playExecutions'],
    },
    {
        component: <PlayDashboardView />,
        path: '/playbook/:playbookId/play/:playId/dashboard',
        requiredPermissions: ['read:playExecutions'],
    },
    {
        component: <DebugView />,
        path: '/developerDebug',
        requiredPermissions: ['read:developer'],
    },
    {
        component: <ConnectSlack />,
        path: '/connect/slack',
    },
    {
        component: <UserSettingsView />,
        path: '/settings',
    },
    {
        component: <SecureFileView />,
        path: '/secureFile/:secureFileId',
        requiredPermissions: ['read:playExecutions']
    }
];

export const publicRoutes: IRoute[] = [
    {
        component: <SignUp />,
        path: '/signup',
    },
    {
        component: <CompleteStepView />,
        path: '/playExecution/:playExecutionId/step/:stepExecutionId',
    },
    {
        component: <EmailSubscriptionView />,
        path: '/email/subscription',
    },
];
