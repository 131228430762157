import { Alert, Button, Grid } from '@mui/material';
import { FC } from 'react';
import { OpenAIConfigurationDto } from '../../../dtos';
import { OpenAiModelSelect } from '../../../Views/UserSettings/Form/OpenAiModelSelect';
import { FormInput, IFormProps } from '../../CoreLib/library';
import { useOpenAIConfigForm } from './useOpenAIConfigForm';

export interface IOpenAIConfigFormProps extends IFormProps<OpenAIConfigurationDto> {
    isClientConfigured: boolean;
}

export const OpenAIConfigForm: FC<IOpenAIConfigFormProps> = (props) => {
    const { isClientConfigured } = props;
    const {
        handleSave,
        handleCancel,
        fieldErrors,
        isFormDirty,
        formOpenAIConfig,
        handleTextFieldChange,
        handleModelChange
    } = useOpenAIConfigForm(props);

    return (
        <Grid item container direction='column' spacing={2} px={2} pt={2}>
            {isClientConfigured ? (
                <Grid item>
                    <Alert severity='info'>
                        OpenAI key has been set for this organization. The key is not displayed below for security reasons but can be updated.
                    </Alert>
                </Grid>
            ) : (
                <Grid item>
                    <Alert severity='warning'>OpenAI key has not been set for this organization. Please enter the key below.</Alert>
                </Grid>
            )}
            <Grid item>
                <FormInput
                    fullWidth
                    label='Key'
                    value={formOpenAIConfig.openAIKey}
                    name='openAIKey'
                    onChange={handleTextFieldChange}
                    errorText={fieldErrors.get('openAIKey')}
                />
            </Grid>
            <Grid item>
                <FormInput
                    fullWidth
                    label='Organization ID'
                    value={formOpenAIConfig.organizationID}
                    name='organizationID'
                    onChange={handleTextFieldChange}
                    errorText={fieldErrors.get('organizationID')}
                />
            </Grid>
            <Grid item>
                <OpenAiModelSelect selectedModel={formOpenAIConfig.model ?? ''} handleModelChange={handleModelChange}/>
            </Grid>
            <Grid container item display='flex' flexDirection='row' justifyContent='flex-end' spacing={3} pb={2}>
                <Grid item>
                    <Button variant='outlined' color='secondary' size='small' onClick={handleCancel} sx={{ width: 90 }}>
                        {isFormDirty ? 'Cancel' : 'Close'}
                    </Button>
                </Grid>
                <Grid item>
                    <Button variant='contained' color='secondary' size='small' onClick={handleSave} sx={{ width: 90 }}>
                        Save
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
};
