import { FormControl, FormHelperText, FormLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import {FC} from 'react';

export interface IOpenAiModelSelectProps {
    errorMessage?: string;
    selectedModel: string;
    handleModelChange: (value: string) => void;
    required?: boolean;
};

export const OpenAiModelSelect: FC<IOpenAiModelSelectProps> = ({
    errorMessage,
    selectedModel,
    handleModelChange,
    required
}) => {

    const onMatchTypeChange = (event: SelectChangeEvent<string>) => {
        handleModelChange(event.target.value);
    }

    return (
        <FormControl fullWidth required={required} error={!!errorMessage}>
            <FormLabel>Model</FormLabel>
            <Select value={selectedModel} onChange={onMatchTypeChange}>
                <MenuItem value={'gpt-4o'}>GPT-4o</MenuItem>
                <MenuItem value={'gpt-4o-mini'}>GPT-4o mini</MenuItem>
                <MenuItem value={'gpt-4'}>GPT-4</MenuItem>
                <MenuItem value={'gpt-4-turbo'}>GPT-4 turbo</MenuItem>
                <MenuItem value={'gpt-3.5-turbo'}>GPT-3.5 turbo</MenuItem>
            </Select>
            <FormHelperText>{errorMessage}</FormHelperText>
        </FormControl>
    );
};