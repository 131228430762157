import { BugReport } from '@mui/icons-material';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { SlimPageHeader, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../Components/CoreLib/library';
import { WysiwygEditorWithDataReferences } from '../../Components/Wysiwyg';
import { TextFieldWithDataReferences } from '../../Components/Wysiwyg/Implementations/TextFieldWithDataReferences';
import { useCreateRemindersSendMutation } from '../../store/generated/generatedApi';
import { TESTING_USE_CASES } from './TestingData';

export const DebugView: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [idx, setIdx] = useState(Number(searchParams.get('idx') ?? 0));
    const [
        sendReminders,
        {
            data: sendRemindersResponse,
            isLoading: isSendingReminders,
            isSuccess: isSendReminderSuccess,
            isError: isSendReminderError,
            reset: resetSendReminder,
        },
    ] = useCreateRemindersSendMutation();
    useSuccessfulActionSnackbar('sent', 'reminders', isSendReminderSuccess, resetSendReminder);
    useFailedActionSnackbar('send', 'reminders', isSendReminderError, resetSendReminder);

    useEffect(() => {
        if (searchParams.get('idx') !== idx.toString()) {
            searchParams.set('idx', idx.toString());
            setSearchParams(searchParams);
        }
    }, [idx, searchParams, setSearchParams]);

    return (
        <Grid container direction='column'>
            <SlimPageHeader icon={<BugReport />} title={`Debug Version ${process.env.REACT_APP_BUILD_IDENTIFIER}`} />
            <Grid item container direction='row' px={2}>
                <Grid item xs={12} sm={6} md={3}>
                    <Card>
                        <CardContent>
                            <Typography variant='h2' mb={1}>
                                Reminders
                            </Typography>
                            <Typography mb={1}>Clicking the button below will send a request to the API triggering the reminder emails to be sent.</Typography>
                            <Button variant='contained' onClick={() => sendReminders()} disabled={isSendingReminders}>
                                Send Reminders
                            </Button>
                            {sendRemindersResponse && (
                                <>
                                    <Typography fontWeight='bold'>Results</Typography>
                                    <pre>{JSON.stringify(sendRemindersResponse, undefined, 2)}</pre>
                                </>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid item>
                <Button onClick={() => setIdx((i) => Math.max(--i, 0))}>Previous</Button> | {idx} |{' '}
                <Button onClick={() => setIdx((i) => Math.min(++i, TESTING_USE_CASES.length - 1))}>Next</Button>
            </Grid>
            <Grid item p={2}>
                <WysiwygEditorWithDataReferences initialValue={TESTING_USE_CASES[idx]} onChange={() => {}} dataReferences={[]}/>
            </Grid>
            <Grid item p={2}>
                <TextFieldWithDataReferences initialValue={'test initial text'} onChange={() => {}} dataReferences={[]}/>
            </Grid>
        </Grid>
    );
};
