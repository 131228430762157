import { Help, History, MoreVert } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Checkbox, Chip, CircularProgress, Divider, IconButton, ListItemButton, Menu, MenuItem, Pagination, Tooltip, Typography } from '@mui/material';
import { FC, useCallback, useMemo, useState } from 'react';
import { LoadingIndicator, StandardSearchInput, useFailedActionSnackbar, useSuccessfulActionSnackbar } from '../../../Components/CoreLib/library';
import { PlayExecutionDto } from '../../../dtos';
import {
    useCreatePlayExecutionBulkCancelMutation,
    useDeletePlayExecutionByIdMutation,
    useGetPlayExecutionQuery,
    useUpdatePlayExecutionByPlayExecutionIdMetadataMutation,
} from '../../../store/generated/generatedApi';
import { formatTimestamp } from '../../../util';
import { CancelPlayModal, SetPlayExecutionNameModal } from '../../Plays/Components';

const PAGE_SIZE = 25;

export interface IRunHistoryList {
    playId: string;
    onRunClicked: (run: string) => void;
}

export const RunHistoryList: FC<IRunHistoryList> = (props) => {
    const { playId, onRunClicked } = props;
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState(0);
    const { data: playExecutions, isLoading: isLoadingPlayExecutions } = useGetPlayExecutionQuery({
        playId,
        searchText,
        sortKey: 'RUN_NUMBER',
        sortAsc: false,
        page,
        pageSize: PAGE_SIZE,
        includeInactive: false,
    });

    const [cancelPlay, { isError: isCancelPlayError, isSuccess: isCancelPlaySuccess, isLoading: isCancelingPlaybook, reset: resetCancelPlay }] =
        useDeletePlayExecutionByIdMutation();
    const [bulkCancel, { isLoading: isBulkCancelLoading, isSuccess: isBulkCancelSuccessful, isError: isBulkCancelUnsuccessful }] =
        useCreatePlayExecutionBulkCancelMutation();
    const [updatePlayExecutionMetadata, { isLoading: isUpdatingRunMetadata, isSuccess: isRunMetadataUpdateSuccessful, isError: isErrorUpdatingRunMetadata }] =
        useUpdatePlayExecutionByPlayExecutionIdMetadataMutation();

    useSuccessfulActionSnackbar('cancelled', 'play', isCancelPlaySuccess, resetCancelPlay);
    useFailedActionSnackbar('cancel', 'play', isCancelPlayError, resetCancelPlay);
    useSuccessfulActionSnackbar('cancelled', 'play(s)', isBulkCancelSuccessful);
    useFailedActionSnackbar('cancel', 'play(s)', isBulkCancelUnsuccessful);
    useSuccessfulActionSnackbar('updated', 'run name', isRunMetadataUpdateSuccessful);
    useFailedActionSnackbar('update', 'run name', isErrorUpdatingRunMetadata);

    const [isCancelConfirmationVisible, setIsCancelConfirmationVisible] = useState(false);
    const [isSetRunNameModalVisible, setIsSetRunNameModalVisible] = useState(false);
    const [selectedMenuRun, setSelectedMenuRun] = useState<PlayExecutionDto | null>(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [selectedRows, setSelectedRows] = useState<string[]>([]);
    const [deletingRunIds, setDeletingRunIds] = useState<string[]>([]);

    const resultPageCount = useMemo(() => {
        if (!playExecutions?.totalQueryResults) {
            return 0;
        }
        return Math.ceil(playExecutions.totalQueryResults / PAGE_SIZE);
    }, [playExecutions?.totalQueryResults]);

    const handleMenuOpen = useCallback(
        (run: PlayExecutionDto) => (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            setSelectedMenuRun(run);
            setMenuAnchorEl(event.currentTarget);
        },
        []
    );

    const handleMenuClose = (afterClose?: () => void) => () => {
        setMenuAnchorEl(null);
        if (afterClose) {
            afterClose();
        }
    };

    const canNotBeCancelled = useCallback(
        (row: PlayExecutionDto): boolean => {
            return isCancelingPlaybook || row.status === 'Complete' || row.status === 'Cancelled';
        },
        [isCancelingPlaybook]
    );

    const handleCancel = () => {
        setIsCancelConfirmationVisible(true);
    };

    const cancelRun = useCallback(() => {
        if (!selectedMenuRun) {
            return;
        }
        setIsCancelConfirmationVisible(false);
        cancelPlay({ id: selectedMenuRun.id });
    }, [cancelPlay, selectedMenuRun]);

    const closeCancelRunModal = () => {
        setIsCancelConfirmationVisible(false);
    };

    const handleRenameClicked = useCallback(() => {
        setIsSetRunNameModalVisible(true);
    }, [])

    const updateRunName = useCallback((name: string) => {
        if (!selectedMenuRun) {
            return;
        }
        updatePlayExecutionMetadata({
            params: { playExecutionId: selectedMenuRun.id },
            payload: { name }
        });
        setIsSetRunNameModalVisible(false);
    }, [updatePlayExecutionMetadata, selectedMenuRun]);

    const cancelRunNameEdit = useCallback(() => {
        setIsSetRunNameModalVisible(false);
    }, []);

    const handleCheckboxChange = (id: string) => {
        if (selectedRows.includes(id)) {
            setSelectedRows(selectedRows.filter((x) => x !== id));
        } else {
            setSelectedRows([...selectedRows, id]);
        }
    };

    const handleBatchDelete = useCallback(() => {
        bulkCancel(selectedRows);
        setDeletingRunIds(selectedRows);
        setSelectedRows([]);
    }, [selectedRows, bulkCancel]);

    const RenderStatusChip = useCallback((status: string) => {
        switch (status) {
            case 'Pending':
                return <Chip size='small' label={status} color='info' />;
            case 'In Progress':
                return <Chip size='small' label={status} color='primary' />;
            case 'Complete':
                return <Chip size='small' label={status} color='success' />;
            case 'Cancelled':
                return <Chip size='small' label={status} color='warning' />;
            default:
                return null;
        }
    }, []);

    return (
        <Box
            style={{
                overflowX: 'hidden',
                width: '100%',
                position: 'relative',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignItems: 'center',
            }}>
            <Box
                display='flex'
                flexDirection='row'
                alignItems='center'
                bgcolor='primary.main'
                color='primary.contrastText'
                width='100%'
                gap={1}
                px={1}
                py={0.5}>
                <History /> <Typography variant='h6'>Run History</Typography>
                <Tooltip title="Here's a list of all the times you've run this play. Click on a specific run, then a step to see data about that step.">
                    <IconButton color='inherit' disableRipple={true}>
                        <Help />
                    </IconButton>
                </Tooltip>
                <Box marginLeft='auto'>
                    <Tooltip title={selectedRows.length === 0 ? 'Select Runs to Cancel' : 'Cancel Selected Runs'}>
                        <span>
                            <IconButton onClick={handleBatchDelete} disabled={selectedRows.length === 0} sx={{ color: 'white' }}>
                                <CancelIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Box>
            </Box>
            <Box width='100%' p={1}>
                <StandardSearchInput
                    searchText={searchText}
                    handleSearchChange={setSearchText}
                    isLoading={isLoadingPlayExecutions}
                    textFieldProps={{
                        size: 'small',
                    }}
                />
            </Box>
            <Box flexGrow={1} sx={{ width: '100%', overflowX: 'hidden', overflowY: 'auto' }}>
                {isLoadingPlayExecutions ? (
                    <LoadingIndicator />
                ) : (
                    <>
                        {playExecutions?.totalQueryResults === 0 && (
                            <Box display='flex' justifyContent='center' alignItems='center' height='100%'>
                                No runs found
                            </Box>
                        )}
                        {playExecutions?.pageResults.map((playExecution) => (
                            <ListItemButton
                                divider
                                onClick={() => onRunClicked(playExecution.id)}
                                sx={{ opacity: playExecution.status === 'Cancelled' ? 0.75 : 1 }}
                                disableGutters>
                                <Box display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' width='100%' gap={1} pl={1}>
                                    <Box display='flex' flexDirection='row' alignItems='center' gap={1} width='100%'>
                                        {isBulkCancelLoading && deletingRunIds.includes(playExecution.id) ? (
                                            <CircularProgress size={24} color='primary' />
                                        ) : (
                                            <Checkbox
                                                checked={selectedRows.includes(playExecution.id)}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCheckboxChange(playExecution.id);
                                                }}
                                                disabled={playExecution.status === 'Complete' || playExecution.status === 'Cancelled' || isBulkCancelLoading}
                                                style={{ padding: 0 }}
                                            />
                                        )}
                                        <Box display='flex' flexDirection='column' width='100%'>
                                            <Box display='flex' flexDirection='row' gap={1} justifyContent='space-between'>
                                                {playExecution.runName ? (
                                                    <Box>{playExecution.runName}</Box>
                                                ) : (
                                                    <Box>Run #{playExecution.runNumber}</Box>
                                                )}
                                            </Box>
                                            {playExecution.startedOn && (
                                                <Typography variant='body2'>Started: {formatTimestamp(playExecution.startedOn)}</Typography>
                                            )}
                                            {playExecution.completedOn && (
                                                <Typography variant='body2'>Completed: {formatTimestamp(playExecution.completedOn)}</Typography>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box>{playExecution.status && RenderStatusChip(playExecution.status)}</Box>
                                    <Box>
                                        <IconButton onClick={handleMenuOpen(playExecution)}>
                                            <MoreVert fontSize='inherit' />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </ListItemButton>
                        ))}
                    </>
                )}
            </Box>
            <Box width='100%' display='flex' flexDirection='column' alignItems='center'>
                <Divider style={{ width: '100%' }} />
                <Pagination
                    count={resultPageCount}
                    page={page + 1}
                    onChange={(_, value) => setPage(value - 1)}
                    shape='rounded'
                    showFirstButton
                    showLastButton
                />
            </Box>

            <Menu
                anchorEl={menuAnchorEl}
                open={!!menuAnchorEl && !!selectedMenuRun}
                onClose={handleMenuClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}>
                <MenuItem onClick={handleMenuClose(handleCancel)} disabled={!!selectedMenuRun && canNotBeCancelled(selectedMenuRun)}>
                    Cancel Run
                </MenuItem>
                <MenuItem onClick={handleMenuClose(handleRenameClicked)} disabled={isUpdatingRunMetadata}>
                    Edit Run Name
                </MenuItem>
            </Menu>
            <CancelPlayModal isOpen={isCancelConfirmationVisible} cancel={closeCancelRunModal} confirm={cancelRun} />
            <SetPlayExecutionNameModal isOpen={isSetRunNameModalVisible} cancel={cancelRunNameEdit} save={updateRunName} existingName={selectedMenuRun?.runName} />
        </Box>
    );
};
